// hooks/useMixpanel.js
import { useEffect } from 'react'
import { trackEvent } from '../utils/mixpanel'

const useMixpanel = (auth, eventName, properties = {}) => {
  const { isAuthenticated, loading, expiry_date, userDetails } = auth
  const email = userDetails?.EMAIL

  useEffect(() => {
    if (isAuthenticated === true && loading === false && expiry_date !== undefined && email) {
      console.log("I'm authenticated", isAuthenticated, loading, expiry_date, email, eventName, properties)
      trackEvent(eventName, properties, userDetails)
    } else if (isAuthenticated === false && loading === false) {
      console.log("I'm not authenticated")
      trackEvent(eventName, properties)
    }
  }, [isAuthenticated, loading, expiry_date, email, eventName, properties])
}

export default useMixpanel
